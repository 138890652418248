import React from 'react';

import styles from './students.module.scss';

import Layout from '../../components/layout';

export default () => (
  <Layout>
    <div className={styles.teamPage}>
      <p>Students Page Main</p>
    </div>
  </Layout>
)